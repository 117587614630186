/* eslint-disable no-unused-vars */

export enum FEATURE {
  CUSTOMER_INTEGRATIONS = 'customer-integrations',
  DOCUMENT_STORAGE = 'document-storage',
  NPS_ASSESSOR_TYPES = 'nps-assessor-types',
  ONBOARDING = 'onboarding',
}

export enum POLARITY {
  POSITIVE = 'positive',
  NEUTRAL = 'neutral',
  NEGATIVE = 'negative',
}

export enum ENVIRONMENT {
  LOCAL = 'local',
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export enum TIME_INCREMENT {
  DAYS7 = 'DAYS7',
  DAYS30 = 'DAYS30',
  DAYS90 = 'DAYS90',
  MONTH = 'MONTH',
  MONTHS12 = 'MONTHS12',
  QUARTER = 'QUARTER',
  WEEK = 'WEEK',
  YEAR = 'YEAR',
  MAX = 'MAX',
}

export const isLocal = process.env.ENVIRONMENT === ENVIRONMENT.LOCAL
export const isProduction = process.env.ENVIRONMENT === ENVIRONMENT.PRODUCTION
export const isStaging = process.env.ENVIRONMENT === ENVIRONMENT.STAGING
export const isDevelopment = process.env.ENVIRONMENT === ENVIRONMENT.DEVELOPMENT

export enum PROD_CLIENT {
  STAFFLINK = 1,
  IMAGE_PROPERTY = 2,
  NITSCHKE_REAL_ESTATE = 5,
  DEMO_CLIENT = 29,
  STAFFLINK_ACADEMY = 32,
  HARCOURTS_RATA_AND_CO = 58,
  HILTON_PARKES_REAL_ESTATE = 48,
  STANSURE_STRATA = 34,
  TEST_HUB = 140,
  MCGRATH_ST_GEORGE = 148,
}

export enum FLAG_FEATURES {
  PROPERTY_GENIE = 'propertyGenie',
  SENTIMENT_ANALYSIS = 'sentimentAnalysis',
  BENCHMARKING = 'benchmarking',
  ORG_CHART = 'orgChart',
  CUSTOM_BASEROW_FORMULA = 'customBaserowFormula',
  EMBEDDED_CARDS = 'embeddedCards',
  PROCESS_MAPS = 'processMaps',
  LEARNER_COURSES = 'learnerCourses',
  COURSE_REPORTING = 'courseReporting',
  KNOWLEDGE_BASE = 'knowledgeBase',
  NPS_INSIGHTS = 'npsInsights',
  INFORMER_DIRECTORY = 'informerDirectory',
  GOALS = 'goals',
  GLOBAL_SEARCH_MODERN = 'globalSearchModern',
  REACT_MAPS = 'reactMaps',
  REVENUE_PROFILES = 'revenueProfiles',
  AUTOMATIONS = 'automations',
  HUBDASH = 'hubDash',
  BASES = 'bases',
  CUSTOMER_INTEGRATIONS = 'customerIntegrations',
  STRIPE_BILLING = 'stripeBilling',
  KNOWLEDGE_BASE_DOCUMENTS = 'knowledgeBaseDocuments',
  HUBS_IMPORTERS = 'hubsImporters',
  AUDIT_LOGS = 'auditLogs',
  KNOWLEDGE_BASE_REWORK = 'knowledgeBaseRework',
}

export enum CUSTOM_HTTP_HEADERS {
  USER_CLIENT_ID = 'x-user-client-id',
  GIT_COMMIT_HASH = 'x-git-commit-hash',
  GIT_COMMIT_TIME = 'x-git-commit-time',
  BROWSER_TAB_ID = 'x-browser-tab-id',
  AUTH_EMAIL = 'x-auth-email',
  IDENTITY_PROVIDER_API_KEY = 'x-api-key',
}

export enum STAFFLINK_CONTACT_INFORMATION {
  PRIMARY_ADDRESS = '4/2 King st, Bowen Hills, Queensland 4006',
  PRIMARY_EMAIL_ADDRESS = 'tech@stafflink.com.au',
  FROM_EMAIL_ADDRESS = 'thehub@stafflink.com.au',
}

export enum HUBSPOT_FORM_ID {
  PROPERTY_GENIE = 'd4af0ef3-b847-4e58-b796-a39ff62a418d',
  SENTIMENT_ANALYSIS = '885953ef-15de-4f06-bbbb-3770b9313778',
}

/**
 * Use getHubUrl() to get the correct URL for the current environment
 */
export enum HUBS_URL {
  PRODUCTION = 'https://thehub.stafflink.com.au',
  STAGING = 'https://staging.thehub.stafflink.com.au',
  DEVELOPMENT = 'https://dev.thehub.stafflink.io',
  LOCAL = 'http://localhost:8910',
}

export enum SEARCH_RESULT_TYPE {
  MEMBERSHIP = 'MembershipSearchResult',
  COURSE = 'LearnerCourseSearchResult',
  PROCESS_MAP = 'ProcessMapsSearchResult',
  KNOWLEDGE_BASE = 'KnowledgeBaseSearchResult',
  HOME_LINKS = 'HomeLinksSearchResult',
  HUBDASHLAYOUT = 'HubDashLayoutSearchResult',
}

export enum AZURE_TOKEN_ERRORS {
  TOKEN_NOT_FOUND = 'Azure Token not found',
  TOKEN_NOT_SET = 'Azure Token not set',
  TOKEN_EXPIRED = 'Azure Token has expired',
  TOKEN_REQUEST_NOT_FOUND = 'Azure Token request record not found',
  TOKEN_REFRESH_FAILED = 'Azure Token refresh failed',
}

export enum BILLING_CYCLE {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export enum NOTIFICATION_RESOURCE_TYPE {
  AUTOMATION = 'AUTOMATION',
  BASEROW = 'BASEROW',
  GOAL = 'GOAL',
  MEMBERSHIP = 'MEMBERSHIP',
  MEMBERSHIP_GROUP = 'MEMBERSHIP_GROUP',
  MILESTONE = 'MILESTONE',
  QUEUER_JOB = 'QUEUER_JOB',
  SENTIMENT_ANALYSIS = 'SENTIMENT_ANALYSIS',
  USER = 'USER',
}

export enum NOTIFICATION_RESOURCE_ROUTE_TYPE {
  BASEROW = 'BASEROW',
  GOAL = 'GOAL',
  SENTIMENT_ANALYSIS_ADMIN_FLAGS = 'SENTIMENT_ANALYSIS_ADMIN_FLAGS',
  SENTIMENT_ANALYSIS_MEMBER_PAGE = 'SENTIMENT_ANALYSIS_MEMBER_PAGE',
}

export enum NOTIFICATION_CODE {
  AUTOMATION_ERROR = 'AUTOMATION_ERROR',
  BASEROW_NOTIFICATION = 'BASEROW_NOTIFICATION',
  GOAL_DUE = 'GOAL_DUE',
  MEMBERSHIP_PROFILE_UPDATED = 'MEMBERSHIP_PROFILE_UPDATED',
  MFA_ENABLED = 'MFA_ENABLED',
  QUEUER_SWITCH_IN_ALERT = 'QUEUER_SWITCH_IN_ALERT',
  WEBHOOK_ERROR = 'WEBHOOK_ERROR',
  SENTIMENT_ANALYSIS_MANUAL_FETCH = 'SENTIMENT_ANALYSIS_MANUAL_FETCH',
}

export enum SUPPORTED_FILE_MIME_TYPES {
  IMAGE = 'image',
  VIDEO = 'video',
  APPLICATION = 'application',
  TEXT = 'text',
}

export enum GoalStatusTypes {
  completed = 'Completed',
  inProgress = 'In Progress',
  blocked = 'Blocked',
  overdue = 'Overdue',
}

export enum NewGoalStatusTypes {
  completed = 'Done',
  inProgress = 'On Track',
  blocked = 'Off Track',
  overdue = 'Overdue',
}
