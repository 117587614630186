// TODO: move all app links to redwood navigate
// FIXME: change layout generation to remove page flash
// TODO: add skeleton loaders or remove loader state across all cells
// TODO: full responsive review
// TODO: set permission levels and implement roles
// TODO: add alias system to auth loader
// TODO: move NavTabs instances to layout with props on url
// TODO: fix sidebar flash - updates coming to router, test direct import?
import { createContext, useMemo, useState } from 'react'

import * as Sentry from '@sentry/browser'
import { isLocal, isProduction } from 'api/src/common/enums'
import { getUniqId } from 'api/src/common/utils'

import Routes from 'src/Routes'

import { HubsAuthType, initialAuth, resetAuth } from './auth'
import { tracePropagationTargets } from './config'
import { AuthClient } from './lib/authClients'
import { useIntercomLoad } from './lib/hooks/UseIntercomLoad'
import Providers from './Providers'

import './styles/ejs2-icons.css'
import './styles/ejs2-rte-content.css'
import './styles/ejs2-tailwind.css'
import './styles/index.css'
import './styles/react-resizable.css'
import './styles/scaffold.css'
import 'highlight.js/styles/github.css'

const webCommitHash = process.env.GIT_COMMIT_HASH
const webCommitTime = process.env.GIT_COMMIT_TIME
const environment = process.env.ENVIRONMENT
const sentrySampleRate = isProduction ? 0.25 : 1

const browserTabId = getUniqId()

Sentry.init({
  dsn: process.env.SENTRY_DSN_FRONTEND,
  environment,
  initialScope(scope) {
    scope.setTag('commitHash', webCommitHash)
    scope.setTag('commitTime', webCommitTime)
    scope.setTag('browserTabId', browserTabId)
    scope.setTag('deploymentSlot', process.env.DEPLOYMENT_SLOT)
    return scope
  },
  release: webCommitHash,
  integrations: [
    Sentry.replayIntegration({}),
    new Sentry.BrowserProfilingIntegration(),
    Sentry.browserTracingIntegration({ tracePropagationTargets }),
  ],
  tracePropagationTargets,
  tracesSampleRate: sentrySampleRate,
  profilesSampleRate: sentrySampleRate,
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: isLocal ? false : true,
})

export const AuthClientContext =
  createContext<[HubsAuthType, (client: AuthClient) => void]>(null)

const App = () => {
  useIntercomLoad()

  const initAuth = useMemo(() => initialAuth, [])
  const [auth, _setAuth] = useState(initAuth)
  const setAuth = (authClient: AuthClient) => _setAuth(resetAuth(authClient))

  return (
    auth && (
      <>
        <div style={{ visibility: 'hidden', display: 'none' }}>
          <p>CommitId:{webCommitHash}</p>
          <p>CommitTime: {webCommitTime}</p>
          <p>DeploymentSlot: {process.env.DEPLOYMENT_SLOT}</p>
        </div>
        <AuthClientContext.Provider value={[auth, setAuth]}>
          <Providers browserTabId={browserTabId}>
            <Routes />
          </Providers>
        </AuthClientContext.Provider>
      </>
    )
  )
}

export default App
